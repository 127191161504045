import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import InteractiveIcon from "../assets/interactiveSession.png";
import talkingIcon from "../assets/talking.png";
import QuestionIcon from "../assets/question.png";
import DownloadIcon from "../assets/download.png";
import PrinterIcon from "../assets/printer.png";
import UserIcon from "../assets/usericon.png";
import { Icon } from "@iconify/react";
import MessageWidget from "../components/MessageWidget";
import Lottie from "react-lottie";
import recorderData from "../assets/voiceRecorderData.json";

import { transport } from "../services/Network";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../services/states/store";
import actions from "../services/states/actions";
import toast from "react-hot-toast";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useNavigate } from "react-router";
import { CircularProgress } from "@mui/material";
import { LangOption } from "../services/states/reducers";
import { Link } from "react-router-dom";

type ChatType = {
  msg: string;
  time: string;
  isSender: boolean;
};

const TrainingConverstations = () => {
  const params = new URLSearchParams(window.location.search);
  const [conversationId, setConversationId] = useState<string>("");
  const [showChatScreen, setShowChatScreen] = useState(false);
  const [localStream, setLocalStream] = useState<MediaStream>();
  const [conversations, setConversations] = useState<ChatType[]>([]);
  // const languages = useSelector((state: RootState) => state.languages);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedGender, setSelectedGender] = useState("FEMALE");
  const [selectedSpeechRate, setSelectedSpeechRate] = useState<number>(1);
  const [recording, setRecording] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const sendMessageRef = useRef<HTMLDivElement>(null);
  const [saving, setSaving] = useState(false);
  const availablePersonas: any[] = useSelector(
    (state: RootState) => state.personas.personas
  );
  const printingStatus: boolean = useSelector(
    (state: RootState) => state.printingStatus
  );
  const selectedPersona: string = useSelector(
    (state: RootState) => state.selectedPersona
  );
  const botSpeech: boolean = useSelector((state: RootState) => state.botSpeech);
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const [selectedAccent, setSelectedAccent] = useState("");
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [transcripted, setTranscripted] = useState("");
  const handleListening = async () => {
    try {
      const stream = await navigator?.mediaDevices?.getUserMedia({
        audio: true,
      });
      if (stream) {
        setLocalStream(stream);
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current?.addEventListener("dataavailable", (event) => {
          audioChunks.current.push(event.data);
        });
        mediaRecorder.current?.start(1000);
        setRecording(true);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const stopHandle = () => {
    mediaRecorder.current?.stop();
    if (mediaRecorder.current) {
      mediaRecorder.current.onstop = async () => {
        const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
        const formData = new FormData();
        formData.append("file", audioBlob);

        try {
          const response = await transport(
            "/trainee/transcribe?lang=" + selectedLanguage,
            {
              method: "POST",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response?.status === 200) {
            const transcript = response?.data?.transcript;
            setInputVal(
              inputVal +
                (transcript?.length > 0
                  ? (inputVal.length > 0 ? " " : "") + transcript
                  : "")
            );
          }
        } catch (error) {
          console.error("Error transcribing audio:", error);
        }

        audioChunks.current = [];
        localStream?.getTracks()[0].stop();
        setRecording(false);
      };
    }
  };
  useEffect(() => {
    if (localStorage.getItem("role") === "trainer") {
      toast.error("Conversation page not available for trainer");
      navigate("/overview");
      return;
    }
    if (printingStatus) {
      dispatch(actions.printingChange(0));
    }

    if (!selectedLanguage) {
      const psettings = localStorage.getItem("p_settings");
      if (psettings) {
        setSelectedLanguage(JSON.parse(psettings).language);
        setSelectedGender(JSON.parse(psettings).gender);
        setSelectedAccent(JSON.parse(psettings).accent);
        setSelectedSpeechRate(JSON.parse(psettings).speech_rate);
      } else {
        setTimeout(() => {
          if (window.history.length <= 2) {
            navigate("/training/sessions", { replace: true });
          } else {
            window.history.back();
          }
          toast.error(
            "Persona language is not setup. Please ask trainer to setup or try again"
          );
        }, 500);
      }
    }
  }, []);

  useEffect(() => {
    if (availablePersonas.length === 0) {
      dispatch(actions.getPersonas());
    }
    if (params.get("id")) {
      if (sessionStorage?.getItem("conversation")) {
        transport("auth/swagger", {
          method: "POST",
          data: {
            urlval:
              process.env.REACT_APP_PERSON_MGMT_URL +
              "/conversation/get_history/" +
              params.get("id"),
            method: "GET",
          },
        }).then((res) => {
          if (res?.status === 200) {
            const rawData = res?.data;
            const messages = rawData?.slice(1, -1)?.split("role: ");
            const msgs = messages
              ?.map((message: string) => {
                const roleMatch = message.match(/\b(user|model)\b/);
                const textMatch = message.match(/text:\s*"([^"]*)"/s);
                const role = roleMatch ? roleMatch[0].trim() : "model";
                const text = textMatch
                  ? textMatch[1]
                      .replace(/\\'/g, "'")
                      .split("\\n")
                      .join(" ")
                      .trim()
                  : "";

                return { content: text, author: role };
              })
              .filter((val: any) => val.content.trim().length > 0);

            setConversations(
              msgs.map((msg: any) => {
                const date = new Date();
                return {
                  msg: msg.content?.replace("content=", "").trim(),
                  isSender: msg.author === "user",
                  time:
                    (date.getHours() <= 9
                      ? "0" + date.getHours()
                      : date.getHours()) +
                    ":" +
                    (date.getMinutes() <= 9
                      ? "0" + date.getMinutes()
                      : date.getMinutes()),
                };
              })
            );
            if (!params.get("id") && !selectedPersona) {
              navigate("/training/sessions", { replace: true });
              setTimeout(() => {
                toast.error("Please select a persona from session first");
              }, 100);
            }
            setConversationId(params.get("id") ?? "");
            setTimeout(() => {
              if (chatBoxRef.current) {
                chatBoxRef.current.scrollTo({
                  behavior: "smooth",
                  top: chatBoxRef.current.scrollHeight,
                });
              }
            }, 300);
          } else {
            setConversationId("");
            window.history.replaceState({}, "", window.location.pathname);
            if (!selectedPersona) {
              navigate("/training/sessions", { replace: true });
              setTimeout(() => {
                toast.error("Please select a persona from session first");
              }, 100);
            }
          }
        });
      } else {
        transport("auth/swagger", {
          method: "POST",
          data: {
            urlval: `${process.env.REACT_APP_PERSON_MGMT_URL}/conversation/end/${conversationId}?save=false`,
            method: "DELETE",
          },
        }).then((response) => {
          setConversationId("");
          setInputVal("");
          window.history.replaceState({}, "", window.location.pathname);
          if (!selectedPersona) {
            navigate("/training/sessions", { replace: true });
          }
        });
      }
    } else {
      setConversationId("");
      if (!selectedPersona) {
        navigate("/training/sessions", { replace: true });
        setTimeout(() => {
          toast.error("Please select a persona from session first");
        }, 100);
      }
    }
    window.addEventListener("popstate", () => {
      setConversationId("");
    });
    return () => {
      window.removeEventListener("popstate", () => {
        setConversationId("");
      });
    };
  }, []);

  return (
    <Layout>
      {conversationId?.length > 0 && showChatScreen ? (
        <div className="mx-auto max-w-[95%] w-[64rem]">
          <div className="flex items-center gap-4 pb-4">
            <img
              className="object-center max-md:w-10"
              src={QuestionIcon}
              alt=""
            />
            <h2 className="font-bold text-xl md:text-3xl text-gray-800">
              Persona: {selectedPersona ? selectedPersona : "Account Query"}
            </h2>
          </div>
          <div>
            <div className="flex items-center rounded-t-md text-white bg-primary justify-between py-1.5 md:py-2 pr-1 md:pr-3 pl-3 md:pl-5 border-l-8 border-bgprimary">
              <div className="flex items-center gap-3">
                <img
                  src={UserIcon}
                  className="rounded-full max-md:w-10"
                  alt="profile"
                />
                <div>
                  <h3 className="md:text-xl font-semibold">Avatar</h3>
                  <p className="font-light text-[10px] md:text-sm pt-1">
                    Online
                  </p>
                </div>
              </div>
              <div className="flex relative items-center gap-3 px-1 text-white">
                <div className="md:pr-2">
                  <img
                    onClick={async () => {
                      if (chatBoxRef.current) {
                        await dispatch(actions.printingChange(2));
                        const pdf = new jsPDF("p", "px", "a4", true);
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = pdf.internal.pageSize.getHeight();
                        let posY = 0;
                        for (
                          let i = 0;
                          i < chatBoxRef.current.children.length;
                          i++
                        ) {
                          const elem = chatBoxRef.current.children.item(
                            i
                          ) as HTMLElement;
                          if (elem) {
                            const canvas = await html2canvas(elem, {
                              scale: 1.5,
                            });
                            const h = canvas.height / 3;
                            if (posY + h >= pdfHeight) {
                              posY = 0;
                              pdf.addPage();
                            }
                            const imgData = canvas.toDataURL("image/png");
                            pdf.addImage(imgData, "PNG", 0, posY, pdfWidth, h);
                            posY += h;
                          }
                        }
                        pdf.save("conversation_" + conversationId + ".pdf");
                      }
                      setTimeout(async () => {
                        await dispatch(actions.printingChange(0));
                      }, 200);
                    }}
                    src={DownloadIcon}
                    className="invert max-md:w-5"
                    alt="download"
                  />
                </div>
                <div className="px-3 md:px-5 border-x border-white text-white">
                  <img
                    onClick={async () => {
                      await dispatch(actions.printingChange(1));
                      window.print();
                      dispatch(actions.printingChange(0));
                    }}
                    src={PrinterIcon}
                    className="invert max-md:w-5 cursor-pointer"
                    alt="printer"
                  />
                </div>
                <Icon
                  onClick={async () => {
                    dispatch(actions.toggleBotSpeech(!botSpeech));
                  }}
                  className="text-lg mx-1 md:text-3xl cursor-pointer"
                  icon={botSpeech ? "ph:speaker-high" : "ph:speaker-x"}
                />
                <div className="pl-2 md:pl-4 border-l border-white text-white">
                  <div
                    onClick={async () => {
                      setSaving(true);
                      try {
                        const response = await transport("auth/swagger", {
                          method: "POST",
                          data: {
                            urlval: `${process.env.REACT_APP_PERSON_MGMT_URL}/conversation/end/${conversationId}?save=true`,
                            method: "DELETE",
                          },
                        });
                        if (response?.status === 200) {
                          toast.success(
                            "Conversation has been ended successfully"
                          );
                          const scoresUpdateCalls = userDetails.groups
                            .filter((group: any) =>
                              group.personas
                                .split(",")
                                .includes(selectedPersona)
                            )
                            .map((group: any) => {
                              return transport("/scores/latest_scores", {
                                method: "POST",
                                data: {
                                  group_id: group.id,
                                  persona_id: selectedPersona,
                                  conversation_id: conversationId,
                                },
                              });
                            });

                          const resp = await Promise.all(scoresUpdateCalls);
                          if (resp.findIndex((val) => val.status == 200) >= 0) {
                            toast.success(
                              "Conversation has been saved successfully!"
                            );
                          }
                          setSaving(false);
                          setConversationId("");
                          setConversations([]);
                          setInputVal("");
                          window.history.replaceState(
                            {},
                            "",
                            window.location.pathname
                          );
                          if (!selectedPersona) {
                            navigate("/training/sessions", { replace: true });
                          }
                        } else {
                          setSaving(false);
                          toast.error(
                            response?.data ?? "Conversations saving failed"
                          );
                        }
                      } catch (err) {
                        setSaving(false);
                        toast.error("Message has unaccepted text data");
                      }
                    }}
                    className="min-w-7 min-h-7 w-9 h-9 bg-[#ff0000] rounded-full p-2"
                  >
                    <Icon
                      className="w-full h-full rotate-[135deg] cursor-pointer"
                      icon="mingcute:phone-outgoing-fill"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="px-1 md:px-3 py-3 xl:px-4 xl:py-5 bg-white rounded-b-md">
              <div
                ref={chatBoxRef}
                className={`flex flex-col items-stretch pb-5 ${
                  !printingStatus ? "max-h-[50vh] overflow-y-auto" : ""
                }`}
              >
                {conversations?.length > 0 ? (
                  conversations.map((convo, index) => (
                    <MessageWidget
                      key={"Conversation_" + index}
                      isSender={convo.isSender}
                      userimg={
                        convo.isSender && userDetails?.profile_pic
                          ? userDetails.profile_pic
                          : UserIcon
                      }
                      text={convo.msg}
                      time={convo.time}
                    />
                  ))
                ) : (
                  <div className="w-full flex items-center justify-center italic text-gray-400 p-4">
                    No messages
                  </div>
                )}
              </div>
              <div className="relative py-1 border border-primary rounded-md bg-bgsecondary">
                <textarea
                  value={
                    transcripted.trim().length > 0
                      ? inputVal.trim() + " " + transcripted.trim()
                      : inputVal
                  }
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      sendMessageRef.current?.click();
                    }
                  }}
                  onChange={(ev) => {
                    setInputVal(ev.target.value);
                  }}
                  className="w-full resize-none [&::-webkit-scrollbar]:hidden focus:outline-none bg-bgsecondary max-md:text-sm pl-2.5 xl:pl-5 py-0.5 items-center pr-20 md:pr-28"
                  placeholder="Text box for typed replies"
                />
                <div
                  className={`flex items-center ${
                    !recording ? "gap-2 md:gap-3.5" : "md:gap-1"
                  }  px-0.5 absolute right-3.5 top-1/2 -translate-y-1/2`}
                >
                  <button
                    onClick={async () => {
                      if (recording) {
                        stopHandle();
                      } else {
                        handleListening();
                      }
                    }}
                  >
                    {!recording ? (
                      <Icon
                        icon="bytesize:microphone"
                        className={`${
                          recording
                            ? "text-2xl md:text-4xl"
                            : "text-xl md:text-3xl"
                        }  text-[#C96600]`}
                      />
                    ) : (
                      <Lottie
                        options={{
                          animationData: recorderData,
                          autoplay: true,
                          loop: true,
                        }}
                        width={window.innerWidth > 767 ? 52 : 32}
                        height={window.innerWidth > 767 ? 64 : 48}
                        isClickToPauseDisabled={true}
                        isStopped={false}
                        isPaused={false}
                      />
                    )}
                  </button>
                  <div
                    ref={sendMessageRef}
                    onClick={async () => {
                      if (
                        inputVal.trim().length > 0 ||
                        transcripted.trim().length > 0
                      ) {
                        const val =
                          inputVal.trim().length > 0 &&
                          transcripted.trim().length > 0
                            ? inputVal.trim() + " " + transcripted.trim()
                            : inputVal.trim() + transcripted.trim();
                        let date = new Date();
                        const convos: any[] = conversations;
                        convos.push({
                          msg: val,
                          isSender: true,
                          time:
                            (date.getHours() <= 9
                              ? "0" + date.getHours()
                              : date.getHours()) +
                            ":" +
                            (date.getMinutes() <= 9
                              ? "0" + date.getMinutes()
                              : date.getMinutes()),
                        });
                        setConversations([...convos]);
                        setInputVal("");
                        setTimeout(() => {
                          if (chatBoxRef.current) {
                            chatBoxRef.current.scrollTo({
                              behavior: "smooth",
                              top: chatBoxRef.current.scrollHeight,
                            });
                          }
                        }, 100);
                        const response = await transport("auth/swagger", {
                          method: "POST",
                          data: {
                            urlval:
                              process.env.REACT_APP_PERSON_MGMT_URL +
                              "/conversation/send_message/",
                            method: "POST",
                            body: JSON.stringify({
                              conversation_id: conversationId,
                              message: val,
                            }),
                          },
                        });
                        date = new Date();
                        if (
                          response?.status === 200 &&
                          response?.data?.trim()
                        ) {
                          convos.push({
                            msg: response.data?.trim(),
                            isSender: false,
                            time:
                              (date.getHours() <= 9
                                ? "0" + date.getHours()
                                : date.getHours()) +
                              ":" +
                              (date.getMinutes() <= 9
                                ? "0" + date.getMinutes()
                                : date.getMinutes()),
                          });
                          if (botSpeech) {
                            const speechResp = await transport(
                              "/trainee/speak?lang=" +
                                selectedAccent +
                                "&gender=" +
                                selectedGender +
                                "&text=" +
                                response.data?.trim()+"&speechRate="+selectedSpeechRate,
                              {
                                method: "GET",
                                responseType: "blob",
                              }
                            );
                            if (speechResp) {
                              const speaker = new Audio(
                                URL.createObjectURL(speechResp.data)
                              );
                              speaker.play();
                            }
                          }
                          setConversations([...convos]);
                        } else {
                          convos.push({
                            msg: "Sorry, no response generated",
                            isSender: false,
                            time:
                              (date.getHours() <= 9
                                ? "0" + date.getHours()
                                : date.getHours()) +
                              ":" +
                              (date.getMinutes() <= 9
                                ? "0" + date.getMinutes()
                                : date.getMinutes()),
                          });
                          setConversations([...convos]);
                        }
                        setTimeout(() => {
                          if (chatBoxRef.current) {
                            chatBoxRef.current.scrollTo({
                              behavior: "smooth",
                              top: chatBoxRef.current.scrollHeight,
                            });
                          }
                        }, 100);
                      }
                    }}
                    className="border-l cursor-pointer border-primary text-primary pl-3 xl:pl-5 text-xl md:text-3xl"
                  >
                    <Icon icon="iconoir:send-solid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-4 items-center justify-center pt-4">
            <p className="text-gray-600 max-md:text-xs">
              Need additional help with completing this assignment? Get in touch
              with your Trainer for assistance
            </p>
            <Link
              to="/help"
              target="_blank"
              className="rounded max-md:text-xs py-1 md:py-2 px-3 md:px-6 bg-[#e6841f] text-white font-light"
            >
              Contact Trainer
            </Link>
          </div>
        </div>
      ) : (
        <>
          <div className="flex w-full pb-2 items-center gap-4">
            <img
              src={InteractiveIcon}
              className="h-10 md:w-[47px] md:h-[47px]"
              alt="interactive"
            />
            <div className="flex-1 self-start">
              <div className="text-3xl md:text-4xl">Conversations</div>
            </div>
          </div>

          <div
            className={`flex items-start gap-4 my-8 bg-white rounded p-5 cursor-pointer border-l-8 border-bgprimary shadow-md`}
          >
            <img
              className="object-center w-12 md:w-[4.5rem] mt-2"
              src={QuestionIcon}
              alt=""
            />
            <div className="flex-[2]">
              <h3 className="font-bold md:text-lg text-gray-800">
                Persona: {selectedPersona ? selectedPersona : "Account query"}
              </h3>
              <p className="text-xs md:text-sm w-full text-gray-500 my-2 line-clamp-2">
                {availablePersonas?.find(
                  (persona) => persona.persona_id == selectedPersona
                )?.persona_desc
                  ? availablePersonas?.find(
                      (persona) => persona.persona_id == selectedPersona
                    ).persona_desc
                  : "No description found."}
              </p>
              <div className="flex items-stretch md:items-center max-md:flex-col gap-5 mt-3">
                {/* <div className="relative">
                  <select
                    name="lang"
                    disabled={
                      conversationId && conversationId.length > 0 ? true : false
                    }
                    id="lang"
                    onChange={(ev) => {
                      setSelectedLanguage(ev.target.value);
                    }}
                    value={selectedLanguage}
                    className="bg-white pr-8 w-full md:w-40 lg:w-52 xl:w-60 max-w-72 appearance-none border text-sm xl:text-base border-black text-gray-400 rounded px-3 py-1.5 lg:py-2 xl:py-2.5 focus:outline-none focus:ring-0"
                  >
                    <option defaultChecked value="">
                      Select your language
                    </option>
                    {languages
                      .filter((lang: LangOption) => lang.value && lang.label)
                      .map((lang: LangOption, index: number) => (
                        <option key={"lang-option-" + index} value={lang.value}>
                          {lang.label}
                        </option>
                      ))}
                  </select>
                  <Icon
                    className="absolute right-2.5 text-gray-700 top-1/2 text-xl pointer-events-none -translate-y-1/2 z-50"
                    icon="tabler:caret-down-filled"
                  />
                </div> */}
                <button
                  disabled={
                    !userDetails &&
                    !selectedPersona &&
                    selectedPersona === "" &&
                    !conversationId
                  }
                  title={
                    !selectedPersona || selectedPersona === ""
                      ? "Please select a persona to begin"
                      : "Begins the conversation"
                  }
                  onClick={() => {
                    if (conversationId) {
                      setShowChatScreen(true);
                    } else {
                      transport("auth/swagger", {
                        method: "POST",
                        data: {
                          urlval: `${process.env.REACT_APP_PERSON_MGMT_URL}/conversation/start/${selectedPersona}?lang=${selectedLanguage}&user_id=${userDetails?.email}`,
                          method: "GET",
                        },
                      }).then((res) => {
                        if (res?.status === 200) {
                          setConversationId(res.data);
                          setShowChatScreen(true);
                          sessionStorage.setItem("conversation", res.data);
                          // sessionStorage.setItem("group", selected)
                          window.history.pushState(
                            {},
                            "",
                            window.location.pathname + "?id=" + res.data
                          );
                        } else {
                          toast.error(
                            "Conversation for this persona cannot be initiated"
                          );
                        }
                      });
                    }
                  }}
                  className="py-2 flex items-center min-w-fit rounded justify-center disabled:opacity-75 px-2 xl:px-3 gap-3 bg-primary text-white"
                >
                  <img src={talkingIcon} className="invert w-6 xl:w-8" alt="" />
                  <span className="whitespace-nowrap max-xl:text-sm">
                    {conversationId
                      ? "Continue Conversation"
                      : "Begin Conversation"}
                  </span>
                </button>
              </div>
            </div>
            <div
              onClick={() => {
                if (conversationId) {
                  setShowChatScreen(true);
                } else if (userDetails && selectedPersona.length > 0) {
                  transport("auth/swagger", {
                    method: "POST",
                    data: {
                      urlval: `${process.env.REACT_APP_PERSON_MGMT_URL}/conversation/start/${selectedPersona}?lang=${selectedLanguage}&user_id=${userDetails?.email}`,
                      method: "GET",
                    },
                  }).then((res) => {
                    if (res?.status === 200) {
                      setConversationId(res.data);
                      sessionStorage.setItem("conversation", res.data);
                      setShowChatScreen(true);
                      window.history.pushState(
                        {},
                        "",
                        window.location.pathname + "?id=" + res.data
                      );
                    } else {
                      toast.error(
                        "Conversation for this persona cannot be initiated"
                      );
                    }
                  });
                } else {
                  toast.error(
                    "Please select persona or try again after few seconds"
                  );
                }
              }}
              className="text-gray-900 max-lg:hidden flex-[1] flex flex-col items-center justify-center self-stretch"
            >
              <Icon className="text-6xl" icon="ph:play-circle" />
              <p className="text-xl font-semibold">
                {conversationId ? "Continue" : "Get Started"}
              </p>
            </div>
          </div>
          <p className="text-gray-600 mb-4 max-md:text-xs">
            Need additional help with completing this assignment? Get in touch
            with your Trainer for assistance
          </p>
          <Link
            to="/help"
            target="_blank"
            className="max-md:text-xs rounded mt-3 md:mt-5 py-2 px-4 md:px-6 bg-[#e6841f] text-white"
          >
            Contact Trainer
          </Link>
        </>
      )}
      {saving ? (
        <div className="fixed top-0 z-50 left-0 w-screen h-screen bg-black bg-opacity-75 flex items-center justify-center">
          <CircularProgress size="60px" />
          <div className="text-white font-semibold text-xl p-6">
            Saving your conversation
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </Layout>
  );
};

export default TrainingConverstations;
