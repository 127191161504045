import React, { useEffect, useRef, useState } from "react";
import Logo from "../assets/logo_with_text.png";
import ChevronDown from "../assets/chevrondown.png";
import UserIcon from "../assets/usericon.png";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../services/states/store";
import actions from "../services/states/actions";
const Navbar = () => {
  const navigate = useNavigate();
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const [showDropDown, setShowDropDown] = useState(false);
  const dispatch = useAppDispatch();
  const [iconPresent, setIconPresent] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("access_token") && !userDetails) {
      dispatch(actions.getUserDetails(localStorage.getItem("role") as string));
    }
    document.addEventListener("click", (ev) => {
      setShowDropDown(false);
    });
    return document.removeEventListener("click", (ev) => {
      setShowDropDown(false);
    });
  }, []);

  return (
    <nav className="top-0 left-0 h-20 z-30 flex items-center justify-between bg-bgprimary px-6 lg:px-12 border-b-[0.625rem] border-[#0168B5]">
      <Icon
        icon="charm:menu-hamburger"
        onClick={() => dispatch(actions.sideBarToggle(true))}
        className={`h-8 w-8 text-white md:hidden  ${
          window.location.pathname.includes("login") ||
          window.location.pathname.includes("forgot-password") ||
          window.location.pathname.includes("reset-password") ||
          window.location.pathname.includes("register")
            ? "hidden"
            : ""
        }`}
      />
      <img
        onClick={() => navigate("/")}
        className={`cursor-pointer ${
          window.location.pathname.includes("login") ||
          window.location.pathname.includes("forgot-password") ||
          window.location.pathname.includes("reset-password") ||
          window.location.pathname.includes("register")
            ? "h-7"
            : "max-md:hidden"
        } md:h-8`}
        src={Logo}
        alt="logo"
      />
      <div className="flex relative items-center max-md:justify-between pl-5 pr-1 lg:px-5">
        <div className="flex max-md:hidden text-white items-center gap-x-2 h-10 flex-nowrap px-6">
          {iconPresent && process.env.REACT_APP_COMPANY_ICON ? (
            <img
              src={process.env.REACT_APP_COMPANY_ICON}
              alt="company icon"
              className="w-10 h-10 rounded-md bg-transparent"
              onError={() => {
                setIconPresent(false);
              }}
            />
          ) : (
            <Icon icon="la:star-solid" fontSize="40px" />
          )}
          <span className="font-bold text-lg" style={{ wordSpacing: "4px" }}>
            {process.env.REACT_APP_COMPANY_NAME ?? "Your Company Name"}
          </span>
        </div>
        <div
          onClick={(ev) => {
            ev.stopPropagation();
            setShowDropDown(!showDropDown);
          }}
          className="flex cursor-pointer items-center gap-x-4 h-10 flex-nowrap pl-4 pr-2 lg:px-6 md:border-l border-white"
        >
          <img
            src={userDetails?.profile_pic ? userDetails.profile_pic : UserIcon}
            className="max-md:hidden rounded-full w-12 h-12 max-w-12 max-h-12"
            alt="usericon"
          />
          <span className="font-bold text-lg text-white">
            {window.location.pathname.includes("login") ||
            window.location.pathname.includes("register")
              ? "Login"
              : userDetails?.fullname ?? "Menu"}
          </span>
          <img src={ChevronDown} className="invert" alt="chevron" />
        </div>
        {showDropDown && (
          <div className="absolute top-12 z-50 flex flex-col items-stretch gap-0 right-10 w-56 bg-white rounded-md overflow-hidden">
            {localStorage.getItem("access_token") && (
              <Link
                to="/my-account"
                className="px-6 pt-3 pb-1.5 cursor-pointer hover:bg-gray-100"
              >
                My account
              </Link>
            )}
            {(localStorage.getItem("access_token") || userDetails) && (
              <div
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
                className="px-6 py-1.5 cursor-pointer hover:bg-gray-100"
              >
                Logout
              </div>
            )}
            <Link
              to="/about"
              className="px-6 py-1.5 cursor-pointer hover:bg-gray-100"
            >
              About
            </Link>
            <Link
              to="/help"
              className="px-6 pt-1.5 pb-3 cursor-pointer hover:bg-gray-100"
            >
              Help
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
