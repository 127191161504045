import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import Layout from "../components/Layout";
import { transport } from "../services/Network";
import LoginBg from "../assets/usericonlogin.png";
import { Icon } from "@iconify/react";
const emailRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
const ResetPassword = () => {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);
  const confirmPassRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const [userId, setUserId] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      navigate("/overview", { replace: true });
    }
    else if(!userId){
      transport("trainee/reset-verify?token="+urlParams.get("token"), {method:"PUT"}).then((res)=>{
        if(res.status===200){
          setUserId(res.data.message);
        }else{
          toast.error((res.data.message??"Invalid reset link")+"! Generate new link")
          navigate("/forgot-password", { replace: true })
        }
      })
    }
  }, []);


  return (
    <Layout showSidebar={false}>
      <div className="w-full bg-[#E6E6E6] h-full max-h-full">
        <div className="relative pt-20 z-10 -top-20 h-screen max-h-screen overflow-hidden">
          <img
            src={LoginBg}
            className="object-cover -z-10 absolute md:-top-12 lg:-top-44 md:-left-28 lg:-left-40"
            alt="user login bg"
          />
          <div className="w-full h-full overflow-y-auto flex flex-col items-center">
            <h1 className="pt-8 pb-4 md:pt-16 md:pb-6 xl:pt-28 xl:pb-11 text-3xl md:text-5xl font-bold">
              Welcome to Avatar
            </h1>
            <div className="bg-white bg-opacity-85 w-11/12 md:w-5/6 lg:w-1/2 2xl:w-[45%] rounded-md px-6 md:px-12 lg:px-16 xl:px-24 2xl:px-36 py-6 md:py-12">
              <h2 className="w-full text-center text-3xl font-bold pt-4">
                Reset Password
              </h2>
              <form
                className="w-full pt-3.5 lg:pt-7"
                onSubmit={async (ev) => {
                  ev.preventDefault();
                  if(!emailRegex.test(passRef.current?.value??"")){
                    toast.error("Password must be 8-32 characters long, with at least one uppercase letter, one lowercase letter, one number, and one special character.")
                    return
                  }
                  if(passRef.current?.value != confirmPassRef.current?.value){
                    toast.error("Confirm password and password must be same");
                    return 
                  }
                  const res = await transport({
                    url: "trainee/reset-password",
                    data: {
                      id: userId,
                      password: passRef.current?.value,
                    },
                    method: "PUT",
                  });

                  if (res?.status === 200) {
                    toast.success(res?.data?.message ?? "Something went wrong");
                    setTimeout(() => {
                      navigate("/login", { replace: true });
                    }, 500);
                  } else {
                    toast.error(res?.data?.message ?? "Something went wrong");
                  }
                }}
              >
                <div className="w-full relative">
                  <label
                    className="block py-2.5 text-gray-700 font-medium"
                    htmlFor="password"
                  >
                    New Password
                  </label>
                  <input
                    name="password"
                    id="password"
                    ref={passRef}
                    type={showPass ? "text" : "password"}
                    required
                    placeholder="Enter New Password"
                    className="border-2 w-full border-gray-400 pl-4 pr-12 py-2 lg:py-3.5 placeholder:text-gray-400 text-black block rounded-md"
                  />
                   <Icon
                    className="text-2xl absolute text-[#28A9E2] right-3.5 top-1/2 translate-y-1/2 cursor-pointer"
                    icon={showPass ? "mdi:eye-off" : "mdi:eye"}
                    onClick={() => setShowPass((prev) => !prev)}
                  />
                </div>
                <div className="w-full relative">
                  <label
                    className="block py-2.5 text-gray-700 font-medium"
                    htmlFor="confirm-password"
                  >
                    Confirm Password
                  </label>
                  <input
                    name="confirm-password"
                    id="confirm-password"
                    ref={confirmPassRef}
                    type={showPassConfirm ? "text" : "password"}
                    required
                    placeholder="Confirm New Password"
                    className="border-2 w-full border-gray-400 pl-4 pr-12 py-2 lg:py-3.5 placeholder:text-gray-400 text-black block rounded-md"
                  />
                   <Icon
                    className="text-2xl absolute text-[#28A9E2] right-3.5 top-1/2 translate-y-1/2 cursor-pointer"
                    icon={showPassConfirm ? "mdi:eye-off" : "mdi:eye"}
                    onClick={() => setShowPassConfirm((prev) => !prev)}
                  />
                </div>
                <button
                  className="w-full my-5 max-lg:py-2.5 p-4 bg-[#0168B5] text-white font-semibold text-lg rounded-md text-center"
                  type="submit"
                >
                  Reset Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
