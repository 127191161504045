import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
// import { GoogleAuth } from "google-auth-library";

// const auth = new GoogleAuth();
const Base_URL =
  process.env.REACT_APP_BASE_URL ?? "https://avatar-dev-svc-dh2o.nw.r.appspot.com";  //  "https://hse-avatar-prod.nw.r.appspot.com"; 
export const transport = axios.create({
  baseURL: Base_URL,
});


transport.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  request.headers.Authorization =
    "Bearer " + localStorage.getItem("access_token");
  return request;
});

transport.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      try{
        const newTokenRes = await axios.get(
          `${Base_URL}/auth/refresh?token=${localStorage.getItem("refresh_token")}`
        );
        if (!newTokenRes || newTokenRes.status !== 200) {
          return newTokenRes;
        }
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.setItem("access_token", newTokenRes.data.access_token);
        localStorage.setItem("refresh_token", newTokenRes.data.refresh_token);
        if (error.config) {
          const config = error.config;
          config.headers.Authorization =
            "Bearer " + newTokenRes.data.access_token;
          return axios(config);
        }
      }catch(err){
        if (err instanceof AxiosError){
            localStorage.clear()
            return err.response
        }
        return err
      }
      
      
    } else {
      return error.response;
    }
  }
);
