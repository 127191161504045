import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../services/states/store";

const MessageWidget = (props: {
  userimg: string;
  isSender: boolean;
  text: string;
  time: string;
}) => {
  const printingStatus: boolean = useSelector(
    (state: RootState) => state.printingStatus
  );
  return (
    <div
      className={`pb-[2.125rem] px-3 pt-2.5 text-gray-800 flex items-center ${
        props.isSender ? "flex-row-reverse" : "flex-row"
      } gap-2 md:gap-3.5 lg:gap-5`}
    >
      <img
        src={props.userimg}
        className={`border border-bgprimary rounded-full max-md:w-10 ${
          printingStatus ? "h-[2.85rem] w-12" : "w-[2.875rem] h-[2.875rem]"
        }`}
        alt="user profile"
      />
      <div className="relative max-w-[60%]">
        <div
          className={`font-light w-fit ${
            printingStatus ? "pb-2 md:pb-4" : "leading-6 py-2 md:py-4"
          } text-xs md:text-sm ${
            props.isSender ? "bg-[#F5F5F5]" : "bg-bgsecondary"
          } rounded-2xl px-3.5 md:px-6 xl:px-8`}
        >
          {props.text}
        </div>
        <p
          className={`text-[10px] md:text-xs absolute text-gray-600  ${
            printingStatus ? "-bottom-2.5 opacity-0" : "pt-1.5"
          } ${props.isSender ? "right-1" : "left-1"}`}
        >
          {props.time}
        </p>
      </div>
    </div>
  );
};

export default MessageWidget;