import { Persona } from "../pages/TrainingPersonasListing";
import BlackIcon from "../assets/Avatar-symbol-black.png";
import BlueIcon from "../assets/Avatar-symbol-blue.png";
import SelectComp, { OptionType } from "./SelectComp";
import { useEffect, useRef, useState } from "react";
import { RootState, useAppDispatch } from "../services/states/store";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { transport } from "../services/Network";
import actions from "../services/states/actions";

interface Props {
  persona: Persona;
  index: number;
  focused: string;
  setFocused: () => void;
  personaSetting:
    | {
        persona_id: string;
        language: string;
        accent: string;
        gender: string;
        speechRate: number;
      }
    | undefined;
}

const PersonaCard = ({
  persona,
  index,
  focused,
  setFocused,
  personaSetting,
}: Props) => {
  const dispatch = useAppDispatch();
  const settings = useSelector((state: RootState) => state.personaSettingsMap);
  const [accents, setAccents] = useState<OptionType[]>([]);
  const languagesVoices = useSelector(
    (state: RootState) => state.languagesVoices
  );
  const languages = useSelector((state: RootState) => state.languages);
  const speechRateRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (settings[persona?.persona_id]?.language && languagesVoices) {
      setAccents(
        languagesVoices
          .filter((voice: any) =>
            voice.voice_lang.includes(
              settings[persona?.persona_id]?.language.value + "-"
            )
          )
          .map((voice: any) => {
            return {
              value: voice.voice_name,
              label: voice.voice_name + "-" + voice.voice_gender,
            };
          })
      );
    } else {
      setAccents([]);
    }
  }, [settings[persona?.persona_id]?.language, languagesVoices]);

  return (
    <div
      onMouseOver={() => setFocused()}
      key={"assesment_card_" + index + "_" + persona.persona_id}
      className={`flex items-center gap-4 bg-white rounded p-5 pb-3 cursor-pointer border-l-8 ${
        focused === persona.persona_id
          ? "border-bgprimary"
          : "border-transparent"
      }`}
    >
      <img
        className="object-center w-10 mt-1 self-start"
        src={focused == persona.persona_id ? BlueIcon : BlackIcon}
        alt=""
      />
      <div className="flex-1">
        <h3 className="font-bold md:text-lg text-gray-800">
          Persona: {persona.persona_id}
        </h3>
        <p className="text-sm w-full max-w-[60rem] text-gray-500 line-clamp-2">
          {persona.persona_desc
            ? persona.persona_desc
            : "No description found."}
        </p>
        <div className="grid gap-10 pt-4 grid-cols-4">
          <SelectComp
            label=""
            key={`${settings[persona?.persona_id]}_lang`}
            options={languages}
            selectedValues={
              settings[persona?.persona_id]?.language
                ? [settings[persona?.persona_id]?.language]
                : []
            }
            setSelectedValues={(values: OptionType[]) => {
              const tempSettings = { ...settings };
              let currentSetting: any = {};
              currentSetting["language"] =
                values.length === 0 ? undefined : values.at(0);
              tempSettings[persona.persona_id] = currentSetting;

              dispatch(actions.updatePersonaSettingsMap(tempSettings));
            }}
            isSingle
            placeholder="Language"
            order={false}
          />
          <SelectComp
            label=""
            options={accents}
            key={`${settings[persona?.persona_id]}_accent`}
            selectedValues={(function () {
              return settings[persona.persona_id]?.accent
                ? [settings[persona.persona_id]?.accent]
                : [];
            })()}
            setSelectedValues={(values: OptionType[]) => {
              const tempSettings = { ...settings };
              const currentSetting = tempSettings[persona.persona_id] ?? {};
              currentSetting.accent =
                values.length === 0 ? undefined : values.at(0);
              if (currentSetting.accent && languagesVoices) {
                const voice = languagesVoices.find(
                  (voice: any) =>
                    voice.voice_name === currentSetting.accent.value
                );
                if (voice) {
                  currentSetting.gender = {
                    value: voice.voice_gender,
                    label: voice.voice_gender,
                  };
                } else {
                  currentSetting.gender = undefined;
                }
              } else {
                currentSetting.gender = undefined;
              }
              tempSettings[persona.persona_id] = currentSetting;
              dispatch(actions.updatePersonaSettingsMap(tempSettings));
            }}
            isSingle
            placeholder="Accent"
            order={false}
          />
          <div className="flex items-center gap-1 text-center bg-bgsecondary w-full border border-gray-400 pl-3 pr-10 py-1.5 text-gray-800 rounded">
            <div className="text-sm whitespace-nowrap" onClick={()=>{
              if(speechRateRef.current!=null){
                speechRateRef.current.focus();
              }
            }}>Speech rate: </div>
            <input placeholder="" ref={speechRateRef} value={settings[persona?.persona_id]?.speechRate} onChange={(ev)=>{
              const tempSettings = { ...settings };
              const currentSetting = tempSettings[persona.persona_id] ?? {};
              currentSetting.speechRate =ev.target.value;
              tempSettings[persona.persona_id] = currentSetting;

              dispatch(actions.updatePersonaSettingsMap(tempSettings));
            }} type="number" className="bg-transparent flex-1 appearance-none [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none text-gray-800 border-0 focus:outline-none focus:ring-0 text-sm" />
          </div>
          <div>
            <button
              onClick={async () => {
                const updateSettings = settings[persona.persona_id];
                if (
                  updateSettings?.accent &&
                  updateSettings?.gender &&
                  updateSettings?.language
                ) {
                  if(updateSettings?.speechRate<0.25 || updateSettings?.speechRate>4.0){
                    toast.error("Invalid speech rate, should be < 4.0 and > 0.25.")
                    return;
                  }
                  const resp = await transport("/trainer/persona", {
                    data: {
                      persona_id: persona.persona_id,
                      accent: updateSettings?.accent.value,
                      language: updateSettings?.language.value,
                      gender: updateSettings?.gender.value,
                      speech_rate:updateSettings?.speechRate
                    },
                    method: "PUT",
                  });
                  if (resp.status === 200) {
                    toast.success(resp.data.message);
                    dispatch(actions.getPersonas());
                  } else {
                    toast.error(
                      resp?.data?.message ??
                        "Persona update failed. Please try again later."
                    );
                  }
                } else {
                  toast.error("Please enter lanugage, accent and gender to update");
                }
              }}
              disabled={
                !settings[persona.persona_id]?.accent ||
                (personaSetting?.accent ===
                  settings[persona.persona_id]?.accent?.value &&
                  personaSetting?.gender ===
                    settings[persona.persona_id]?.gender?.value &&
                  personaSetting?.language ===
                    settings[persona.persona_id]?.language?.value&&
                  personaSetting?.speechRate ===
                    settings[persona.persona_id]?.speechRate)
              }
              className="bg-primary disabled:opacity-50 px-8 py-1.5 max-w-fit self-center rounded-md text-white font-bold"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonaCard;
