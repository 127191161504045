import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import TableTopBar from "../components/TableTopBar";
import AssessmentIcon from "../assets/assesment.png";
import Icon from "../assets/assesmentpen.png";
import active from "../assets/assesmentpen.svg";
import { useSelector } from "react-redux";
import { RootState } from "../services/states/store";

interface Assessment {
  assessment_id: string;
  description: string;
}

const TrainingAssesmentsListing = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [focused, setFocused] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const assessmentsData: Assessment[] = useSelector(
    (state: RootState) => state.assesments
  );
  const [filteredAssessmentsData, setFilteredAssessmentsData] = useState<
    Assessment[]
  >([]);

  useEffect(() => {
    if (searchInput?.length > 0) {
      setFilteredAssessmentsData(
        assessmentsData.filter(
          (persona: Assessment) =>
            persona.assessment_id
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            persona.description
              .toLowerCase()
              .includes(searchInput.toLowerCase())
        )
      );
    } else {
      setFilteredAssessmentsData(assessmentsData);
    }
  }, [searchInput, assessmentsData]);
  return (
    <Layout>
      <div className="flex w-full pb-2 items-center gap-4">
        <img src={AssessmentIcon} alt="user" className="max-md:h-10" />
        <div className="flex-1">
          <div className="text-3xl md:text-4xl">Assessments</div>
          <p className="line-clamp-2 max-md:text-sm">
            Once the Trainee has completed the conversation, these assessments will be used to determine how well the trainee did in handling the conversation.
          </p>
        </div>
      </div>
      <TableTopBar
        setAction={() => {}}
        currentPage={currentPage}
        pageName="Assessment"
        setCurrentPage={setCurrentPage}
        showAction={false}
        showViewAll={false}
        updateSearchValue={setSearchInput}
        type={1}
        totalCount={Math.ceil(
          filteredAssessmentsData ? filteredAssessmentsData.length / 8 : 0
        )}
      />
      <div className="flex flex-col items-stretch gap-y-4 py-6">
        {filteredAssessmentsData.length > 0 ? (
          filteredAssessmentsData
            ?.slice(currentPage * 8, currentPage * 8 + 8)
            .map((assessment, index) => (
              <div
                onMouseOver={() => setFocused(assessment.assessment_id)}
                key={"assesment_card_" + index}
                className={`flex items-center gap-4 bg-white rounded p-2.5 md:p-5 cursor-pointer border-l-8 ${
                  focused === assessment.assessment_id
                    ? "border-bgprimary"
                    : "border-transparent"
                }`}
              >
                <img
                  className="object-center w-12"
                  src={focused === assessment.assessment_id ? active : Icon}
                  alt=""
                />
                <div className="flex-1">
                  <h3 className="font-bold md:text-lg text-gray-800">
                    Assessment: {assessment.assessment_id}
                  </h3>
                  <p className="text-sm w-full max-w-[60rem] text-gray-500 line-clamp-2">
                    {assessment.description}
                  </p>
                </div>
              </div>
            ))
        ) : (
          <div className="text-center text-gray-500 italic p-8">
            No assessment found
            {searchInput.length > 0 ? " with given searched value" : ""}
          </div>
        )}
        <TableTopBar
          setAction={() => {}}
          currentPage={currentPage}
          pageName="Assessment"
          setCurrentPage={setCurrentPage}
          showAction={false}
          showViewAll={false}
          type={2}
          totalCount={Math.ceil(
            filteredAssessmentsData ? filteredAssessmentsData.length / 8 : 0
          )}
        />
      </div>
    </Layout>
  );
};

export default TrainingAssesmentsListing;
