import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
export type OptionType = {
  label: string;
  value: any;
  [key: string]: string;
};
interface Props {
  label: string;
  options: OptionType[];
  selectedValues: OptionType[];
  placeholder: string;
  order: boolean;
  isSingle?: boolean;
  setSelectedValues: (val: any) => void;
}
const SelectComp = ({
  label,
  options,
  selectedValues,
  placeholder,
  order,
  setSelectedValues,
  isSingle,
}: Props) => {
  const [inputVal, setInputVal] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<OptionType[]>([]);
  useEffect(() => {
    document.addEventListener("click", () => {
      setShowOptions(false);
    });
    return () => {
      document.addEventListener("click", () => {
        setShowOptions(false);
      });
    };
  }, []);
  useEffect(() => {
    const optionsFiltered: OptionType[] = options?.filter(
      (option: OptionType) =>
        option.value &&
        (inputVal.trim().length === 0 ||
          option.label
            .toString()
            .toLowerCase()
            .includes(inputVal.trim().toLowerCase()))
    );
    setFilteredOptions([...optionsFiltered]);
  }, [options, inputVal, selectedValues]);
  return (
    <div
      onClick={(ev) => {
        ev.stopPropagation();
      }}
    >
      {label && (
        <label className="block pb-1 text-gray-600" htmlFor="trainees">
          {label}
        </label>
      )}
      <div className="relative">
        <input
          name="trainees"
          id="trainees"
          placeholder={
            showOptions || selectedValues.length === 0
              ? placeholder
              : selectedValues.map((val) => val.label).toString()
          }
          value={showOptions ? inputVal : ""}
          onClick={() => {
            setShowOptions(true);
          }}
          onChange={(ev) => {
            setInputVal(ev.target.value);
          }}
          className={
            "bg-bgsecondary appearance-none w-full border border-gray-400 pl-3 pr-10 py-1.5 text-gray-400 rounded focus:outline-none focus:ring-0 text-sm" +
            (showOptions || selectedValues.length === 0
              ? " placeholder:text-gray-400"
              : " placeholder:text-gray-800")
          }
        />
        <Icon
          onClick={() => {
            setShowOptions(!showOptions);
          }}
          className="absolute right-4 text-gray-700 top-1/2 text-xl -translate-y-1/2 z-30"
          icon="tabler:caret-down-filled"
        />
        {showOptions && (
          <div className="absolute top-9 z-40 rounded text-sm flex flex-col items-stretch left-0 bg-bgsecondary w-full border overflow-y-auto max-h-44 border-gray-400 text-gray-600">
            {filteredOptions.length > 0
              ? filteredOptions
                  .sort((a, b) =>
                    a?.label.toString().localeCompare(b?.label.toString())
                  )
                  .map((option, index) => {
                    const selected =
                      selectedValues.findIndex(
                        (val) => val.value === option.value
                      ) !== -1;
                    return (
                      <div
                        onClick={() => {
                          if (selected) {
                            setSelectedValues(
                              isSingle
                                ? []
                                : [
                                    ...selectedValues.filter(
                                      (val) => val.value !== option.value
                                    ),
                                  ]
                            );
                          } else {
                            setSelectedValues(
                              isSingle
                                ? [
                                    filteredOptions.find(
                                      (val) => option.value === val.value
                                    ),
                                  ]
                                : [
                                    ...selectedValues,
                                    filteredOptions.find(
                                      (val) => option.value === val.value
                                    ),
                                  ]
                            );
                          }
                        }}
                        key={
                          placeholder.trim().replace(/ /g, "_") +
                          "_" +
                          option.value +
                          "_" +
                          index
                        }
                        className={`px-3 py-0.5 flex items-center justify-between cursor-pointer hover:bg-gray-100 ${
                          order ? (selected ? "order-1" : "order-2") : ""
                        }`}
                      >
                        <span>{option.label}</span>
                        <div
                          className={`w-4 cursor-pointer h-4 p-0.5 mr-1 flex items-center justify-center md:mr-4 mt-1 rounded-md border-2 ${
                            selected
                              ? "border-[#E6841F] text-[#E6841F]"
                              : "border-gray-500"
                          } `}
                        >
                          {selected ? (
                            <Icon icon="streamline:check-solid" />
                          ) : null}
                        </div>
                      </div>
                    );
                  })
              : inputVal.length > 0 && (
                  <div
                    className={`px-3 py-1 flex items-center justify-center italic cursor-pointer hover:bg-gray-100`}
                  >
                    <span>Not found</span>
                  </div>
                )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectComp;
